<template>
  <HeaderRow>
    <template #button>
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="handleAddAdmin"
        >
          新增超管
        </el-button>
      </div>
    </template>
  </HeaderRow>

  <BaseDrawer
    :status="drawerStatus"
    title="添加超级管理员"
    @close="closeDrawer"
    @btn-submit="clickBtnSubmit"
    size="33%"
    direction="rtl"
  >
    <template #body>
      <div class="drawer-body">
        <el-autocomplete
          class="admin-autocomplete"
          v-model="adminInfo.name"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入姓名关键词"
          @select="handleSelectAdminName"
          :debounce="1000"
        ></el-autocomplete>
      </div>
    </template>
  </BaseDrawer>

  <!-- 添加管理员 -->
  <!-- <UserSearch title="添加管理员" @confirm="add"></UserSearch> -->

  <BaseTable
    :tableHeight="tableHeight"
    :tableColumns="tableColumns"
    :tableData="tableData"
    :loading="tableLoading"
    @deleteAbovers="handleClickDelete"
  >
    <template #empty>
      <div class="table-nodata">
        <img src="../../../assets/noDataBg.png" alt="" srcset="" />
        <a href="javascript:viod(0)" class="btn-add" @click="handleAddAdmin">
          新增超管
        </a>
      </div>
    </template>
  </BaseTable>

  <AdminModal
    :showModal="showDelModal"
    btnType="3"
    sureText="确认删除"
    cnacel="取消"
    @cancel="showDelModal = false"
    @submit="delCurrentAdmin()"
  >
    <template v-slot:body>
      <div class="body-wrap">
        <i class="el-icon-question"></i>
        <p>确定要删除此管理员？</p>
      </div>
    </template>
  </AdminModal>
</template>

<script>
import {
  aboveSearch,
  aboverRemove,
  aboverAdd,
  searchUserLikeName,
  setAllByType,
} from "@/utils/api";
import {
  ref,
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
// import UserSearch from "@/components/UserSearch";
import HeaderRow from "../components/HeaderRow";
import BaseDrawer from "../components/BaseDrawer";
import BaseTable from "../components/BaseTable";
import AdminModal from "../components/AdminModal";
import { ElMessage } from "element-plus";

export default {
  components: { HeaderRow, BaseDrawer, BaseTable, AdminModal },
  setup() {
    let state = reactive({
      drawerStatus: false,
      showDelModal: false, // 模态框--当前管理员可删除
      currRowData: "", // 当前选中的行数据
      tableLoading: false,
      tableData: [],
      tableHeight: 0,
      tableColumns: [
        {
          prop: "userId",
          label: "用户ID",
          columnKey: "userId",
          filterMultiple: false,
          minWidth: "150px",
          fixed: true,
        },
        {
          prop: "name",
          label: "详姓名情",
          minWidth: "180px",
        },
        {
          prop: "created",
          label: "创建时间",
          minWidth: "150px",
        },
        {
          prop: "creatorName",
          label: "创建人",
          minWidth: "150px",
        },
        {
          prop: "updated",
          label: "更新时间",
          minWidth: "260px",
        },
        {
          prop: "updaterName",
          label: "更新人",
          minWidth: "260px",
        },
        {
          // prop: "operation",
          label: "操作",
          minWidth: "260px",
          operation: [
            { name: "删除", clickFun: "deleteAbovers", icon: "el-icon-delete" },
          ],
        },
      ],
      // input搜索绑定name
      adminInfo: {
        name: "",
      },
      // 点击表格删除按钮
      handleClickDelete(rowData) {
        state.showDelModal = true;
        state.currRowData = rowData;
      },
      // modal框确定事件
      delCurrentAdmin() {
        remove(state.currRowData.id, () => {
          state.showDelModal = false;
        });
      },
      // 模糊查询管理员列表
      querySearchAsync(query, cb) {
        if (query !== "") {
          state.adminSelectLoading = true;
          searchUserLikeName({ name: query }).then((res) => {
            state.adminSelectLoading = false;
            const data = res.data.map((item) => {
              return { value: item.name, ...item };
            });
            cb(data);
          });
        }
      },
      // input搜索结果并选中
      handleSelectAdminName(item) {
        state.adminInfo = item;
      },
      // 动态获取table高度
      getHeight() {
        state.tableHeight = window.innerHeight  - 200;
      },
    });
    // 搜索超管列表
    function search() {
      state.tableLoading = true;
      aboveSearch().then((res) => {
        state.tableLoading = false;
        state.tableData = res.data.map((item) => {
          return { ...item.base, ...item };
        });
        
      });
    }
    // 移除超管
    function remove(id, cb) {
      if (id) {
        let data = { ids: [id] };
        aboverRemove(data).then((res) => {
          cb();
          search();
        });
      }
    }
    // 添加超管
    function add(data, cb) {
      let param = [
        {
          name: data.name,
          userId: data.id,
        },
      ];
      aboverAdd(param).then((res) => {
        cb();
        search();
      });
    }
    // 新增超管-按钮
    const handleAddAdmin = () => {
      state.drawerStatus = true;
    };
    // 关闭抽屉事件
    const closeDrawer = () => {
      state.drawerStatus = false;
    };
    // 点击提交
    const clickBtnSubmit = () => {
      if (state.adminInfo.name === "") {
        ElMessage({
          showClose: true,
          message: "请输入姓名关键词搜索相应的管理员",
          type: "warning",
        });
      } else {
        state.drawerStatus = false;
        add(state.adminInfo, () => {
          state.drawerStatus = false;
          state.adminInfo.name = "";
        });
      }
    };
    onBeforeMount(() => {
      search();
      state.getHeight()

    });
    onMounted(() => {
      window.addEventListener("resize", state.getHeight);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", state.getHeight);
    });
    const refData = toRefs(state);
    return {
      search,
      remove,
      add,
      handleAddAdmin,
      closeDrawer,
      clickBtnSubmit,
      ...refData,
    };
  },
  // mounted: function() {
  //   this.search();
  // },
};
</script>

<style lang="scss" scoped>
@import "@/styles/element-ui.scss";
@import "@/styles/mixin.scss";
@import "@/styles/button.scss";
// modal
.body-wrap {
  height: 100%;
  @include flex(column, center);
  &::v-deep(.el-icon-question) {
    font-size: 65px;
    color: #ee3b40;
    margin-top: -60px;
  }
  &::v-deep(.el-icon-warning) {
    font-size: 65px;
    color: #ee3b40;
    margin-top: -60px;
  }
  p {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #595959;
    line-height: 23px;
    margin: 23px auto 13px;
  }
  span {
    font-size: 14px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #9e9e9e;
    line-height: 23px;
  }
}
// table无数据
.table-nodata {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  img {
    width: 412px;
    height: 235px;
  }
}
// 抽屉
.drawer-body {
  text-align: center;
  padding: 30px 50px;
  &::v-deep(.el-autocomplete) {
    height: 100%;
    width: 100%;
    height: 61px;
    .el-input {
      height: 100%;
      width: 100%;
      // 下拉框
      .el-input__inner {
        height: 100%;
        width: 100%;
        background: #f8f8f8;

        &::placeholder {
          font-size: 16px;
          font-family: SourceNormal;
          font-weight: 400;
          color: #a5a9af;
        }
      }
      .el-input__suffix {
        margin-right: 20px;
        // 下拉icon
        .el-select__caret {
          color: #333;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
