<!-- BaseDrawer -->
<template>
  <el-drawer
    v-model="drwaerOpenStatus"
    :direction="direction"
    :size="size"
    @close="handleCloseDrawer"
    class="base-el-drawer"
  >
    <p class="base-drawer-title">{{ title }}</p>
    <slot name="body"></slot>
    <div class="btn-wrap">
      <BaseButton
        type="btn-default"
        size="btn-large"
        class="cancel-add-conf"
        @click="handleCloseDrawer"
        >取消</BaseButton
      >
      <BaseButton type="btn" size="btn-large" @click="handleClickBtn"
        >确定</BaseButton
      >
    </div>
  </el-drawer>
</template>

<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  computed,
  watch,
} from "vue";
import BaseButton from "@/views/Admin/components/BaseButton";
export default {
  name: "BaseDrawer",
  components: {
    BaseButton,
  },
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    direction: {
      type: String,
      default: "rtl",
    },
    size: {
      type: String,
      default: "33%",
    },
  },
  emits: ["close", "btnSubmit"],
  setup(props, { emit }) {
    watch(
      () => props.status,
      (value) => {
        state.drwaerOpenStatus = value;
      }
    );
    const state = reactive({
      drwaerOpenStatus: false,
    });

    const handleCloseDrawer = () => {
      emit("close", state.drwaerOpenStatus);
    };
    const handleClickBtn = () => {
      emit("btnSubmit");
    };
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(state);
    return {
      ...refData,
      handleCloseDrawer,
      handleClickBtn,
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/button.scss";
// 抽屉--新增类型
.el-drawer {
  ::v-deep(.el-drawer__close-btn) {
  outline: none;
}
  // 抽屉title
  .base-drawer-title {
    font-size: 30px;
    font-family: SourceRegular;
    font-weight: bold;
    color: #303235;
    margin: 0 0 40px 48px;
  }
  // 按钮组
  .btn-wrap {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 88px;
    justify-content: center;
    .cancel-add-conf {
      border: 1px solid transparent;
      background: #fff;
      &:hover {
        color: #227fff;
      }
    }
    button {
      margin: 0 20px;
    }
  }
}
</style>
